import { useState, useCallback, useEffect } from "react"
import _ from "lodash"
import axios from "axios"
import * as Sentry from "@sentry/react"

import { PAYMENT_TYPE } from "../constants"

import config from "../config"

const inRange = function (num, start, end) {
  // If no end number, use start as end
  if (!end) {
    end = start
    start = 0
  }
  return num >= start && num <= end
}

const parseModelMatrix = (matrix) => {
  if (!matrix) return []
  const rows = _.split(matrix, "\r").filter((str) => !_.isEmpty(str))
  return rows.map((row) => {
    const values = row.split(",")
    const [down_payment, term, balloon, hpe, hpbl, fl, gfv, gfv_amount] = values
    return {
      down_payment: down_payment.split("-").map(Number), // [down_payment, down_payment + 4],
      term: Number(term),
      balloon: _.split(balloon, "-").map(Number), // [1, balloon],
      [`rate_${PAYMENT_TYPE.HIRE_PURCHASE}`]: Number(hpe),
      [`rate_${PAYMENT_TYPE.HIRE_PURCHASE_WITH_BALLOON}`]: Number(hpbl),
      [`rate_${PAYMENT_TYPE.FINANCIAL_LEASE}`]: Number(fl),
      gfv_amount: _.split(gfv_amount, "-").map(Number),
    }
  })
}

const parseTier = (tier) => {
  return {
    ...tier,
    value: tier.id,
    year: tier.year,
    matrix: parseModelMatrix(tier.matrix),
  }
}

const useUsedCarProvider = ({ isLogin, token }) => {
  const [models, setModels] = useState([])

  const getModelMatrix = useCallback(
    (year) => {
      if (!models) return null
      const modelEntity = models.find((m) => `${m.year}` === `${year}`)
      return modelEntity?.matrix
    },
    [models]
  )

  const findSFRate = useCallback(
    (
      year,
      type_of_payment,
      term,
      down_payment = 35,
      balloon = 0,
      output = "value"
    ) => {
      if (!type_of_payment)
        throw new Error(
          `findMatrixRow need type_of_payment, receive ${type_of_payment}`
        )

      const matrix = getModelMatrix(year)
      if (!matrix) throw new Error(`cannot find matrix for year ${year}`)

      const result = matrix.find((row) => {
        if (type_of_payment === PAYMENT_TYPE.HIRE_PURCHASE) {
          return (
            row.term === term &&
            inRange(
              Math.floor(down_payment),
              row.down_payment[0],
              row.down_payment[1]
            )
          )
        }

        return (
          row.term === term &&
          inRange(
            Math.floor(down_payment),
            row.down_payment[0],
            row.down_payment[1]
          ) &&
          inRange(balloon, /* row.balloon[0] */ 0, row.balloon[1])
        )
      })

      if (!result)
        console.log(`Cannot find result with parameter`, {
          year,
          type_of_payment,
          term,
          down_payment,
          balloon,
          result,
        })

      return output === "value"
        ? _.get(result, `rate_${type_of_payment}`, 0)
        : result
    },
    [models]
  )

  const getUsedcarTiers = useCallback(async () => {
    const items = await axios
      .get(`${config.baseUrl}/api/used-car/tiers`)
      .catch((err) => {
        Sentry.captureException(err)
      })
      .then((res) => res.data.data)
      .then((tiers) => _.filter(tiers, (tier) => tier.deleted_at === null))

    const models = _.map(items, parseTier)
    setModels(models)
  }, [token])

  const submitQuotation = (data) => {
    console.log(`submitQuotation`, data)
    return axios
      .post(`${config.apiEndpoint}/used-car/quotations`, data, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        return res.data
      })
      .then((response) => {
        console.log(`submitQuotation::response`, response)
        const { id, url } = response.data
        return { id, url }
      })
  }

  const getQuotationPdf = async (quotationId) => {
    const result = await axios
      .get(`${config.apiEndpoint}/used-car/quotations/${quotationId}/pdf`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        return res.data
      })
      .then((response) => {
        return response.data.url
      })
    return result
  }

  // const getQuotationImageZip = async (quotationId) => {
  //   const result = await axios
  //     .get(`${config.apiEndpoint}/used-car/quotations/${quotationId}/jpg`, {
  //       headers: { Authorization: `Bearer ${token}` },
  //     })
  //     .then((res) => {
  //       return res.data
  //     })
  //     .then((response) => {
  //       return response.data.url
  //     })
  //   return result
  // }

  useEffect(() => {
    if (isLogin) getUsedcarTiers()
  }, [isLogin])

  return {
    findSFRate,
    getModelMatrix,
    models,
    submitQuotation,
    getQuotationPdf,
    // getQuotationImageZip,
  }
}

export default useUsedCarProvider
