import {
  useCallback,
  useEffect,
  useReducer,
  createContext,
  useContext,
} from "react"
import axios from "axios"
import * as Sentry from "@sentry/react"

import _ from "lodash"
import config from "../config"
import { PAYMENT_METHOD, PAYMENT_TYPE } from "../constants"

import useAuthProvider from "./useAuthprovider"
import useUsedCarProvider from "./useUsedCarProvider"

const DOMAINS = {
  DEFAULT: "default",
  CORPORATE_SALE: "corporateSale",
}

const initialState = {
  brand: undefined,
  brandId: 0,
  allSeries: [],
  series: [],
  models: [],
  articles: [],
  accessories: [],
  domain: DOMAINS.DEFAULT,
}

const mediaRoot =
  `${process.env.REACT_APP_BASE_URL}/media/model` || 
  `https://bmwleasingthailand.com/media/model`

const inRange = function (num, start, end) {
  // If no end number, use start as end
  if (!end) {
    end = start
    start = 0
  }
  return num >= start && num <= end
}

const parseSerie = (serie) => {
  return {
    ...serie,
    value: serie.id,
    label: serie.name,
    media_url: `${mediaRoot}/${serie.media_path}${serie.media}`,
  }
}

const parseModelMatrix = (matrix) => {
  if (!matrix) return []
  const rows = _.split(matrix, "\r").filter((str) => !_.isEmpty(str))
  // console.log({ rows })
  return rows.map((row) => {
    const values = row.split(",")
    const [down_payment, term, balloon, hpe, hpbl, fl, gfv, gfv_amount] = values
    return {
      down_payment: down_payment.split("-").map(Number), // [down_payment, down_payment + 4],
      term: Number(term),
      balloon: _.split(balloon, "-").map(Number), // [1, balloon],
      [`rate_${PAYMENT_TYPE.HIRE_PURCHASE}`]: Number(hpe),
      [`rate_${PAYMENT_TYPE.HIRE_PURCHASE_WITH_BALLOON}`]: Number(hpbl),
      [`rate_${PAYMENT_TYPE.FINANCIAL_LEASE}`]: Number(fl),
      [`rate_${PAYMENT_TYPE.FREEDOM_CHOICE}`]: Number(gfv),
      gfv_amount: _.split(gfv_amount, "-").map(Number),
    }
  })
}

const parseModelMatrixGFV = (matrix) => {
  if (!matrix) return []
  const rows = _.split(matrix, "\r").filter((str) => !_.isEmpty(str))
  // console.log({ rows })
  return rows.map((row) => {
    const values = row.split(",")
    const [down_payment, term, balloon, gfv] = values
    return {
      down_payment: down_payment.split("-").map(Number), // [down_payment, down_payment + 4],
      term: Number(term),
      balloon: _.split(balloon, "-").map(Number), // [1, balloon],
      [`rate_${PAYMENT_TYPE.FREEDOM_CHOICE}`]: Number(gfv),
    }
  })
}

const parseModel = (model, domain) => {
  const defaultMatrix = parseModelMatrix(model.matrix)
  const defaultMatrixGfv = parseModelMatrixGFV(model.matrix_gfv)

  const corporateSaleMatrix = parseModelMatrix(model.corporatesale_matrix)
  const corporateSaleMatrixGfv = parseModelMatrixGFV(
    model.corporatesale_matrix_gfv
  )

  return {
    ...model,
    value: model.id,
    label: model.name,
    serie_id: model.model_id,
    price: parseInt(model.price.replace(/,/g, ""), 10),
    bsi_3: Number(model.bsi_3),
    bsi_4: Number(model.bsi_4),
    bsi_5: Number(model.bsi_5),
    bsi_6: Number(model.bsi_6),
    has_freedomchoice: model.is_gfv_matrix === 1,

    default_matrix: defaultMatrix,
    default_matrix_gfv: defaultMatrixGfv,
    corporateSale_matrix: corporateSaleMatrix,
    corporateSale_matrix_gfv: corporateSaleMatrixGfv,

    /**
     ** Set Current Matrix
     * Note: This shouldn't be necessary, but is left here in case there are some code
     * in components/pages that use model.matrix directly.
     * I did search the project and didn't see anycode that call model.matrix directly, but just to be safe.
     */
    matrix:
      domain === DOMAINS.CORPORATE_SALE ? corporateSaleMatrix : defaultMatrix,
    matrix_gfv:
      domain === DOMAINS.CORPORATE_SALE
        ? corporateSaleMatrixGfv
        : defaultMatrixGfv,
  }
}

const filterSeries = (series, models) => {
  if (!models) return series
  return _.filter(series, (serie) => {
    const hasModels = _.some(models, (model) => model.serie_id === serie.id)
    return hasModels
  })
}

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_BRAND": {
      const brandId = (() => {
        switch (action.brand) {
          case "mini":
            return 2
          case "motorrad":
            return 3
          case "bmw":
          default:
            return 1
        }
      })()
      return {
        ...state,
        brandId: brandId,
        brand: action.brand,
      }
    }
    case "RECEIVED_SERIES":
      return {
        ...state,
        allSeries: action.series,
        series: filterSeries(action.series, state.models),
      }
    case "RECEIVED_MODELS":
      return {
        ...state,
        models: action.models,
        series: filterSeries(state.allSeries, action.models),
      }
    case "RECEIVED_ARTICLES":
      return { ...state, articles: action.articles }
    case "RECEIVED_QUOTATIONS":
      const quoteState = { ...state.quotations }
      const data = action.data || quoteState.data || []
      const err = action.err || ""
      const quotations = { data, err }
      return { ...state, quotations }
    case "SET_DOMAIN": {
      const domain = action.value
      console.log(`setDomain: ${domain}`)
      const nextState = {
        ...state,
        domain,
      }
      return nextState
    }
    case "RECEIVED_ACCESSORIES": {
      return {
        ...state,
        accessories: action.accessories,
      }
    }
    default:
      throw new Error()
  }
}

const DataProviderContext = createContext(initialState)

export const DataProvider = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const { domain, brand, brandId, models, accessories } = state
  const { isLogin, token } = useAuthProvider()

  const usedCarProvider = useUsedCarProvider({ isLogin, token })

  const switchDomain = useCallback((value) => {
    dispatch({ type: "SET_DOMAIN", value })
  }, [])

  // Brand
  const setBrand = useCallback((brand) => {
    console.log(`setBrand: ${brand}`)
    dispatch({ type: "SET_BRAND", brand })
  }, [])

  const getSerieList = useCallback(
    async (query) => {
      const { brandId } = query
      if (!brandId) return
      const items = await axios
        .get(`${config.apiEndpoint}/models?brand_id=${brandId}`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .catch((err) => {
          Sentry.captureException(err)
        })
        .then((res) => res.data.data)
        .then((series) =>
          _.filter(series, (serie) => serie.deleted_at === null)
        )
        .then((series) => _.orderBy(series, ["order"], ["asc"]))

      const series = _.map(items, parseSerie)
      dispatch({ type: "RECEIVED_SERIES", series })
    },
    [token]
  )

  const getSeries = useCallback(
    async (query) => {
      const { brandId } = query
      if (!brandId) return
      return await axios
        .get(`${config.apiEndpoint}/models?brand_id=${brandId}`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .catch((err) => {
          Sentry.captureException(err)
        })
        .then((res) => res.data.data)
        .then((series) =>
          _.filter(series, (serie) => serie.deleted_at === null)
        )
        .then((series) => _.orderBy(series, ["order"], ["asc"]))
    },
    [token]
  )

  const getAllModelList = useCallback(async () => {
    const { domain } = state
    const items = await axios
      .get(`${config.apiEndpoint}/tiers`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => res.data.data)
      .then((models) => _.filter(models, (model) => model.deleted_at === null))

    const models = _.map(items, (item) => parseModel(item, domain))
    dispatch({ type: "RECEIVED_MODELS", models })
  }, [token, domain])

  const getAllAccessories = useCallback(async () => {
    const accessories = await axios
      .get(`${config.apiEndpoint}/accessories`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "Content-Type,X-Amz-Date,Authorization,X-Api-Key,X-Amz-Security-Token",
          "Access-Control-Allow-Methods": "GET,POST,PUT,DELETE,OPTIONS,HEAD"
        },
      })
      .then((res) => res.data.data)
    dispatch({ type: "RECEIVED_ACCESSORIES", accessories })
  }, [token])

  const getSeriesWheels = useCallback(
    async (serieId) => {
      return await axios
        .get(
          `${config.apiEndpoint}/model/${serieId}/accessories?category=wheel`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((res) => res.data.data)
    },
    [token]
  )

  const getModelsBySerieId = (serieId) =>
    models.filter((model) => model.serie_id === serieId)

  const getAccessoriesBySerieId = (serieId) => {
    if (serieId === "all") return accessories
    if (!serieId) return []
    return accessories.filter(
      (accessory) =>
        (accessory.brand_id === null || accessory.brand_id === brandId) &&
        (accessory.model_ids === null ||
          accessory.model_ids.indexOf(`${serieId}`) > -1)
    )
  }

  // const getArticles = async () => {
  //   try {
  //     const articles = await axios
  //       .get(`${config.apiEndpoint}/bulletins`, {
  //         headers: { Authorization: `Bearer ${token}` },
  //       })
  //       .then((res) => res.data.data)
  //     dispatch({ type: "RECEIVED_ARTICLES", articles })
  //   } catch (err) {
  //     console.log(`cannot fetch articles: ${err.message}`)
  //   }
  // }

  const getArticleById = useCallback(
    async (id) => {
      try {
        const result = await axios
          .get(`${config.apiEndpoint}/bulletins/${id}`, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((res) => res.data.data)
        return result[0]
      } catch (err) {
        console.log(`cannot fetch articles: ${err.message}`)
      }
    },
    [token]
  )

  const findBsiPrice = useCallback(
    (model, year) => {
      const modelEntity = models.find((m) => m.id === model)
      return _.get(modelEntity, `bsi_${year}`, 0)
    },
    [models]
  )

  const findMaxBsi = useCallback(
    (model) => {
      const modelEntity = models.find((m) => m.id === model)
      const bsiRange = [
        _.get(modelEntity, `bsi_3`, 0),
        _.get(modelEntity, `bsi_4`, 0),
        _.get(modelEntity, `bsi_5`, 0),
        _.get(modelEntity, `bsi_6`, 0),
      ]
      return Math.max(...bsiRange)
    },
    [models]
  )

  const findSFRate = useCallback(
    (
      model,
      type_of_payment,
      payment_method,
      term,
      down_payment = 35,
      balloon = 0,
      output = "value"
    ) => {
      if (payment_method === PAYMENT_METHOD.CASH) return 5.55

      const modelEntity = models.find((m) => m.id === model)

      if (!modelEntity) return 0

      const theMatrix = findModelMatrix(modelEntity, type_of_payment, domain)

      // Freedom Choice
      if (type_of_payment === PAYMENT_TYPE.FREEDOM_CHOICE) {
        const result = theMatrix.find((row) => {
          return (
            row.term === term &&
            inRange(
              Math.floor(down_payment),
              row.down_payment[0],
              row.down_payment[1]
            )
          )
        })

        return output === "value"
          ? _.get(result, `rate_${type_of_payment}`, 0)
          : result
      } else {
        const result = theMatrix.find((row) => {
          return (
            row.term === term &&
            inRange(
              Math.floor(down_payment),
              row.down_payment[0],
              row.down_payment[1]
            )
            // && inRange(balloon, row.balloon[0], row.balloon[1])
          )
        })
        return output === "value"
          ? _.get(result, `rate_${type_of_payment}`, 0)
          : result
      }
    },
    [models, domain]
  )

  const findDownPaymentBoundaryByTerm = useCallback(
    (model, type_of_payment, term) => {
      const modelEntity = models.find((m) => m.id === model)
      if (!modelEntity) return []
      const theMatrix = findModelMatrix(modelEntity, type_of_payment, domain)

      const downPaymentByTerm = theMatrix.filter(
        (matrix) => matrix.term === term
      )
      const minMatrixRow = _.minBy(downPaymentByTerm, (row) =>
        _.get(row, "down_payment.0")
      )
      const maxMatrixRow = _.maxBy(downPaymentByTerm, (row) =>
        _.get(row, "down_payment.1")
      )

      const min = _.get(minMatrixRow, "down_payment.0", 15)
      const max = _.get(maxMatrixRow, "down_payment.1", 50)

      return [min, max]
    },
    [models, domain]
  )

  const findModelMatrix = useCallback(
    (model, type_of_payment, domain = DOMAINS.DEFAULT) => {
      switch (type_of_payment) {
        case PAYMENT_TYPE.FREEDOM_CHOICE:
          return model[`${domain}_matrix_gfv`]
        default:
          return model[`${domain}_matrix`]
      }
    },
    []
  )

  const findAvailableTerms = useCallback(
    (model, type_of_payment, down_payment = 35) => {
      const modelEntity = models.find((m) => m.id === model)
      if (!modelEntity) return []

      // Freedom Choice use matrix_gfv, else use matrix
      const theMatrix = findModelMatrix(modelEntity, type_of_payment, domain)
      if (!theMatrix) return []

      const availableConfigs = theMatrix.filter((row) => {
        const rate = _.get(row, `rate_${type_of_payment}`, 0)
        return (
          inRange(
            Math.floor(down_payment),
            row.down_payment[0],
            row.down_payment[1]
          ) && rate > 0
        )
      })
      return availableConfigs.map((row) => row.term)
    },
    [models, domain]
  )

  const submitQuotation = (data) => {
    console.log(`submitQuotation`, data)
    return axios
      .post(`${config.apiEndpoint}/quotations`, data, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        return res.data
      })
      .then((response) => {
        console.log(`submitQuotation::response`, response)
        const { id, url } = response.data
        return { id, url }
      })
  }

  const createAndSendQuotation = (data) => {
    console.log("create and send Quotation", data)
    return axios
      .post(`${config.apiEndpoint}/data-consent/send-quotation`, data, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        return res.data
      })
      .then((response) => {
        console.log(`createAndSendQuotation::response`, response)
        const { id, url, reference_id, is_verified } = response.data
        return { id, url, reference_id, is_verified }
      })
  }

  const resendConsentSMS = ({ referenceId, language = "TH" }) => {
    console.log(`resending ${referenceId}`)
    return axios
      .get(
        `${config.apiEndpoint}/data-consent/resend-sms/${referenceId}?language=${language}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        return res.data
      })
      .then((response) => {
        console.log(`resendConsentSMS::response`, response.data)
        const { reference_id, send_try_number } = response.data
        return { reference_id, send_try_number }
      })
  }

  const getQuotationPdf = async (quotationId) => {
    const result = await axios
      .get(`${config.apiEndpoint}/quotations/${quotationId}/pdf`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        return res.data
      })
      .then((response) => {
        return response.data.url
      })
    return result
  }

  // const getQuotationImageZip = async (quotationId) => {
  //   const result = await axios
  //     .get(`${config.apiEndpoint}/quotations/${quotationId}/jpg`, {
  //       headers: { Authorization: `Bearer ${token}` },
  //     })
  //     .then((res) => {
  //       return res.data
  //     })
  //     .then((response) => {
  //       return response.data.url
  //     })
  //   return result
  // }

  const submitAccessoryQuotation = (data) => {
    console.log(`submitAccessoryQuotation`, data)
    return axios
      .post(`${config.apiEndpoint}/accessory-quotations`, data, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        return res.data
      })
      .then((response) => {
        const { id, url } = response.data
        return { id, url }
      })
  }

  const getAccessoryQuotationPdf = async (quotationId) => {
    const result = await axios
      .get(`${config.apiEndpoint}/accessory-quotations/${quotationId}/pdf`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        return res.data
      })
      .then((response) => {
        return response.data.url
      })
    return result
  }

  const getQuotations = useCallback(
    async (params) => {
      try {
        const { id, brandId, authorId, skip, limit } = params
        const fromId = id ? `id=${id}&` : ""
        const fromBrand = brandId ? `brandId=${brandId}&` : ""
        const fromAuthor = authorId ? `authorId=${authorId}&` : ""
        const endpoint = `${config.apiEndpoint}/quotations?${fromId}${fromBrand}${fromAuthor}skip=${skip}&limit=${limit}`
        const headers = { Authorization: `Bearer ${token}` }
        const quotations = await axios.get(endpoint, { headers })
        dispatch({ type: "RECEIVED_QUOTATIONS", data: quotations.data.data })
      } catch (error) {
        dispatch({ type: "RECEIVED_QUOTATIONS", err: `${error}` })
      }
    },
    [token]
  )

  const getQuotationById = async (quotationId) => {
    try {
      const result = await axios
        .get(`${config.apiEndpoint}/quotations/${quotationId}`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          return res.data.data
        })
      return result
    } catch (err) {
      console.log(`cannot fetch quotation: ${err.message}`)
    }
  }

  const getBookingFormInitialValueFromQuotation = async (
    quotationId,
    quotationInfoId
  ) => {
    try {
      const result = await axios
        .get(`${config.apiEndpoint}/quotations/${quotationId}`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          return res.data.data
        })
      return result.find(
        (quotation) => `${quotation.id}` === `${quotationInfoId}`
      )
    } catch (err) {
      console.log(`cannot fetch quotation: ${err.message}`)
    }
  }

  const submitBooking = (data) => {
    console.log(`submitBooking`, data)
    return axios
      .post(`${config.apiEndpoint}/booking`, data, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        return res.data
      })
      .then((response) => {
        console.log(`submitBooking::response`, response)
        const { bookingId: id, url } = response.data
        return { id, url }
      })
  }

  const getBookingPdf = async (bookingId) => {
    const result = await axios
      .get(`${config.apiEndpoint}/bookings/${bookingId}/pdf`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        return res.data
      })
      .then((response) => {
        console.log({ response })
        return response.data.url
      })
    return result
  }

  const submitCorporateSale = (data) => {
    return axios
      .post(`${config.apiEndpoint}/corporate-sale/quotations`, data, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        return res.data
      })
      .then((response) => {
        console.log(`submitCorporateSale::response`, response)
        const { id, url } = response.data
        return { id, url }
      })
  }

  const getCorporateSaleQuotationPdf = async (quotationId) => {
    const result = await axios
      .get(
        `${config.apiEndpoint}/corporate-sale/quotations/${quotationId}/pdf`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        return res.data
      })
      .then((response) => {
        console.log({ response })
        return response.data.url
      })
    return result
  }

  useEffect(() => {
    if (isLogin && brand) {
      try {
        getSerieList({ brandId })
        if (models.length === 0) getAllModelList()
        if (accessories.length === 0) getAllAccessories()
      } catch (err) {
        // on error, send to login
        console.log(err)
        console.log(`cannot fetch series: `, { brandId, brand })
      }
    }
  }, [isLogin, brand, brandId, getSerieList, getAllModelList, models.length])

  /* eslint-disable react-hooks/exhaustive-deps */
  // useEffect(() => {
  //   if (isLogin) getArticles()
  // }, [isLogin])
  /* eslint-enable react-hooks/exhaustive-deps */

  return (
    <DataProviderContext.Provider
      value={{
        ...state,
        domain,
        models: models.map((model) => ({
          ...model,
          matrix:
            domain === DOMAINS.CORPORATE_SALE
              ? model.corporatesale_matrix
              : model.default_matrix,
          matrix_gfv:
            domain === DOMAINS.CORPORATE_SALE
              ? model.corporatesale_matrix_gfv
              : model.default_matrix_gfv,
        })),
        token,
        setBrand,
        getModelsBySerieId,
        findBsiPrice,
        findMaxBsi,
        findDownPaymentBoundaryByTerm,
        findSFRate,
        findAvailableTerms,
        submitQuotation,
        createAndSendQuotation,
        resendConsentSMS,
        submitAccessoryQuotation,
        getArticleById,
        getQuotationPdf,
        getAccessoryQuotationPdf,
        // getQuotationImageZip,
        getQuotations,
        getQuotationById,
        getBookingFormInitialValueFromQuotation,
        submitBooking,
        getBookingPdf,
        submitCorporateSale,
        getCorporateSaleQuotationPdf,
        getAccessoriesBySerieId,
        getSeriesWheels,
        usedCarProvider,
        domain,
        switchDomain,
        getSeries,
      }}
    >
      {props.children}
    </DataProviderContext.Provider>
  )
}

const useDataprovider = () => {
  const state = useContext(DataProviderContext)
  return state
}

export default useDataprovider
